import { onMounted, onUpdated } from 'vue'

function noop() {
  /* do nothing... */
}
/**
 *
 * @param {Element} container  container是DOM类型，表示container下的<img />全都需要预加载
 * @param {*} dynamicSrcs   // 数组中包含着你想要预加载的动态图素链接
 * @param {*} callback 全部图素预加载完成的回调函数
 * @returns
 */
export default function useAllImageLoaded(container, dynamicSrcs, callback) {
  if (arguments.length < 1) {
    // 没参数是没有意义的
    console.warn('At least 1 parameter are required！ ——from useAllImageLoaded Hook.')
    return false
  }

  if (arguments.length === 1) {
    // 如果带入1个参数，只能是 动态链接集合
    // 此时只有参数为需要动态加载的图片集合方面存在意义，如果参数为container或者callback函数都没有意义
    if (Array.isArray(container)) {
      dynamicSrcs = container
      container = null
      callback = noop
    } else {
      // 如果用户代入错误的参数情况，终止预加载，并抛出警告
      console.warn(
        'When only 1 parameter is brought in, it is required that an image link array be required！ ——from useAllImageLoaded Hook.'
      )
      return false
    }
  }

  if (arguments.length === 2) {
    // 如果带入2个参数，就是 (动态链接集合 + 回调) 和 （container + 回调）
    // 如果是 (container + 动态链接集合）实际上没什么意义，但我们也进行container下的预加载处理
    if (typeof dynamicSrcs === 'function') {
      if (Array.isArray(container)) {
        // (动态链接集合 + 回调)
        callback = dynamicSrcs
        dynamicSrcs = container
        container = null
      } else {
        // （container + 回调）
        callback = dynamicSrcs
        dynamicSrcs = []
      }
    } else if (Array.isArray(dynamicSrcs)) {
      // (container + 动态链接集合）
      callback = noop
    }
  }

  function collectImageLoaded() {
    // 如果container是null或undefined，说明当前用户无需做静态图片预加载
    if ([null, undefined].includes(container.value)) {
      console.warn('useAllImageLoaded hook warning: no container, no static images preloaded!')
      container = null
    }

    if (container !== null && !(container.value instanceof HTMLElement)) {
      // 如果container存在，但不是DOM类型，说明用户有心预加载，但参数错误，用body替代container
      console.warn(
        'useAllImageLoaded hook warning: container is not a HTMLElement instance, use body'
      )
      container = { value: document.body }
    }

    let images = []

    const imgDomsUnderContainer = Array.prototype.slice.call(
      (container?.value && container?.value.querySelectorAll('img')) || []
    )

    const dynamicImages =
      (dynamicSrcs &&
        dynamicSrcs.map((imgSrc) => {
          // 将动态链接实例化为<img />实例
          const img = new Image()
          img.src = imgSrc
          return img
        })) ||
      []

    images = [...imgDomsUnderContainer, ...dynamicImages]

    const promises = images.map((node) => {
      return new Promise((resolve) => {
        function onceLoaded() {
          node.removeEventListener('load', onceLoaded)
          resolve(true)
        }

        if (node.complete) {
          // 考虑到页面更新，某些图片src未发生改变，diff后复用的情况下，load事件不会被触发
          // 因此对于已经load又被复用的图片，应该根据node.complete属性来进行判断。
          resolve(true)
        } else {
          node.addEventListener('load', onceLoaded)
        }
      })
    })

    Promise.allSettled(promises)
      .then(() => {
        callback && callback()
      })
      .catch((e) => {
        console.error('网络异常或其他程序异常', e)
      })
  }

  onMounted(() => {
    console.log('useAllImageLoaded mounted')
    collectImageLoaded()
  })

  onUpdated(() => {
    collectImageLoaded()
  })
}
