<template>
  <div class="big_box">
    <div class="header_box">
      <img src="@/assets/images/agent/logo.png" alt="" />
      <div v-if="userStore.hasLogin" class="info">
        <!-- <p class="name">{{userStore.userInfo.realName}}</p> -->
        <div class="info_box">
          <img :src="userStore.userInfo.avatarUrl" alt="" />
          <p class="balance">￥{{ userStore.balance }}</p>
          <img :src="refreshIcon" alt="" class="refresh_icon" @click="togetBalance"
            :class="roate ? 'rotate-class' : ''" />
          <p class="name">欢迎! {{ userStore.userInfo.realName || userStore.userInfo.userName }}</p>
        </div>
        <div class="exit" @click="exitLogin">退出</div>
      </div>
    </div>
    <el-container class="container">
      <el-container>
        <Transition name="expand" mode="out-in" v-if="!$route.meta.isHideMenu">
          <el-aside width="210px" class="aside">
            <HMenu class="h-menu" :menus="menus" :actived="route.fullPath" />
          </el-aside>
        </Transition>
        <div class="main_container proxy_container">
          <el-main>
            <section :class="$route.meta.isHideMenu ? 'full-screen' : 'content'">
              <HRouteView keepAlive />
            </section>
          </el-main>
        </div>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { HMenu, HRouteView } from '@/components'
import proxyRoutes from '@/router/proxy'
import { useUserStore } from '@/stores/user.js'
import refreshIcon from '@/assets/images/agent/refresh.png'
import { getUserBalance, userLogout } from '@/api/user.js'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'
const userStore = useUserStore()

onMounted(() => {
  window.addEventListener('storage', handleStorageEvent);
});

onUnmounted(() => {
  window.removeEventListener('storage', handleStorageEvent);
});
// const channel = new BroadcastChannel('shared-channel');
// channel.onmessage = (event) => {
//   const data = JSON.parse(event.data)
//   if (data.type === 'update') {
//     delete data.type
//     userStore.$patch({ ...data });
//     if (!userStore.isProxy) {
//       router.push('/404')
//     }
//   }
// };
const handleStorageEvent = (event) => {
  if (event.key === 'close-window') {
    window.close();
  }
};

const route = useRoute()
const menus = proxyRoutes
const roate = ref(false)
const router = useRouter()

const togetBalance = async () => {
  roate.value = true
  let { result: res } = await getUserBalance()
  userStore.setAttribute('balance', res.balance)
  setTimeout(() => {
    roate.value = false
  }, 1000)
}

const exitLogin = async () => {
  const { result } = await userLogout()
  if (result) {
    ElMessage.success('已退出登录')
    setTimeout(() => {
      userStore.$reset()
      router.push('/')
    }, 1000)
  }
}
</script>
<style lang="scss" scoped>
.big_box {
  background: #fff;
  height: 100vh;

  :deep(.aside) {
    width: 320px;

    .is-active {
      background: #d9e6fa !important;
      color: #488ded;
    }
  }

  .main_container {
    width: calc(100% - 320px);
    padding: 20px;
    box-sizing: border-box;
    background-color: $pageBgColor;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow-y: auto;

    .el-main {
      height: 100%;
    }

    .content {
      height: 100%;
    }
  }
}

.header_box {
  width: 100%;
  height: 60px;
  width: 100%;
  height: 60px;
  color: #333;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.8;
  background-color: #fff;

  .name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }

  img {
    width: 122px;
    height: 31px;
  }

  .info {
    display: flex;
    align-items: center;

    .exit {
      border-radius: 4px;
      text-align: center;
      line-height: 34px;
      color: #ff0000;
      background: none;
      cursor: pointer;
    }
  }

  .info_box {
    display: flex;
    align-items: center;
    margin-right: 20px;

    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }

    .balance {
      margin-right: 10px;
    }

    .refresh_icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-right: 10px;
    }

    .rotate-class {
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      0% {
        -webkit-transform: rotate(0deg);
      }

      25% {
        -webkit-transform: rotate(90deg);
      }

      50% {
        -webkit-transform: rotate(180deg);
      }

      75% {
        -webkit-transform: rotate(270deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}

.container {
  // width: 100vw;
  height: calc(100% - 60px);
  background: #fff;
  position: relative;
  z-index: 0;

  .el-container {}

  .right-top-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 830px;
  }

  .aside {
    height: 100%;
    padding: 0;
    position: relative;
    overflow: hidden;

    .logo {
      width: 200px;
      margin: 15px 0;

      &:active {
        opacity: 0.7;
      }
    }

    // .el-menu {
    // 	border-right: none;
    // 	min-width: 200px;

    // }
    .h-menu {
      border-right: none;
      min-width: 200px;

      :deep(.el-menu-item) {
        height: 56px;
        width: 100%;
        background-color: #fff;

        &.is-active {
          color: #488ded;
        }
      }

      :deep(.el-sub-menu__title) {
        &>span {
          display: flex !important;
          align-items: center !important;
        }
      }

      :deep(.el-menu-item.is-active) {
        color: $primaryColor;
      }

      :deep(.el-sub-menu.is-active) {
        .el-sub-menu__title {
          color: $primaryColor;
        }
      }

      :deep(.el-sub-menu.is-active) {
        .el-sub-menu {
          .el-sub-menu__title {
            color: $primaryColor;
          }
        }

        .el-sub-menu.is-active {
          .el-sub-menu__title {
            color: $primaryColor;

            &>span {
              display: flex !important;
              align-items: center !important;
            }
          }
        }
      }
    }
  }

  .collapse-aside {
    .cloud-logo {
      cursor: pointer;
      transition:
        transform 0.2s,
        opacity 0.2s;

      &:active {
        transform: scale(0.9);
        opacity: 0.7;
      }
    }

    .el-row {
      border-right: solid 1px var(--el-menu-border-color);
    }

    .el-menu {
      :deep(.el-menu-item) {
        padding: 0 0 0 15px;
        box-sizing: border-box;
        cursor: pointer;
        transition:
          transform 0.1s,
          opacity 0.1s;

        &:active {
          transform: scale(0.8);
          opacity: 0.7;
        }
      }
    }
  }

  .collapse,
  .expand {
    position: absolute;
    bottom: 56px;
    right: 22px;
    width: 24px;
    transition:
      transform 0.2s,
      box-shadow 0.2s,
      filter 0.2s;
    cursor: pointer;

    &:hover {
      filter: brightness(1.2);
      /* 鼠标移入时亮度增加 */
    }
  }

  .expand {
    left: 18px;
    transform: rotatey(180deg);
  }

  .el-main {
    padding: 0;
    margin: 0;

    .content {
      // overflow: auto;
      // height: calc(100vh - 80px);
      // padding: 10px 15px 10px 15px;
    }

    .full-screen {
      // overflow: auto;
      // height: 100vh;
    }
  }

  .el-header {
    padding: 0;
  }
}

.collapse-leave-active,
.expand-leave-active {
  transition: all 0.5s ease;
}

.collapse-leave-to {
  width: 60px;
  opacity: 0.5;
}

.expand-leave-to {
  width: 200px;
  opacity: 0.5;
}
</style>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(50, 208, 229, 0.5);
}
</style>
