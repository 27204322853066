<template>
  <div class="notice_box">
    <el-dialog v-model="visible" class="notice_dialog" title="最新公告" :append-to-body="true" :show-close="true"
      width="680" center>
      <div style="width: 100%;">
        <el-carousel height="400px" v-if="noticeContent && noticeContent.length">
          <el-carousel-item v-for="(item, index) in noticeContent" :key="index">
            <el-scrollbar height="400px" :always="true">
              <div class="ntice_title" style="text-align: center; color: #488ded; font-size: 18px; margin-bottom: 16px">
                {{ item.title }}
              </div>
              <p class="ntice_details" v-html="item.content"></p>
            </el-scrollbar>
          </el-carousel-item>
        </el-carousel>
        <div class="flex-center" style="margin-top: 20px;">
          <el-checkbox v-model="homeStore.hideNotices">{{ userStore.hasLogin ? '本次登录' : '' }}不再提醒</el-checkbox>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" style="width: 150px; height: 40px" @click="visible = false">
            我知道了
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useHomeStore } from '@/stores/home.js'
import { useUserStore } from '@/stores/user.js'
const homeStore = useHomeStore()
const userStore = useUserStore()

const noticeContent = ref([])
const visible = ref(false)
const open = (lists) => {
  noticeContent.value = lists
  visible.value = true
}
defineExpose({ open })
</script>
<style lang="scss" scoped>
:deep(.ntice_details) {
  * {
    margin: revert !important;
    // font-size: revert!important;
    // font: revert!important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert !important;
  }

  strong {
    font-weight: bold !important;
  }

  img {
    width: 100%;
    height: auto;
  }
}
</style>
