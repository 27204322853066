import request from '@/utils/request'
//获取额度转换所有平台列表
export function getPlatList(params) {
  return request({
    url: '/plat/getPlatList',
    method: 'get',
    params
  })
}
//获取三方平台余额
export function platGetBalancec(platCode) {
  return request({
    url: `/plat/getBalancec/${platCode}`,
    method: 'get'
  })
}
//额度转入
export function platDeposit(data) {
  return request({
    url: '/plat/deposit',
    method: 'post',
    data
  })
}
//额度转出
export function platWithdraw(data) {
  return request({
    url: '/plat/withdraw',
    method: 'post',
    data
  })
}
//一键转出所有额度
export function platWithdrawAll(data) {
  return request({
    url: '/plat/withdrawAll',
    method: 'post',
    data
  })
}
//自动转入开关
export function updateAutoTransfer(data) {
  return request({
    url: '/user/updateAutoTransfer',
    method: 'post',
    data
  })
}

// 三方游戏投注记录
export function platOrderList(params) {
  return request({
    url: '/user/platOrder/list',
    method: 'get',
    params
  })
}

// 个人报表
export function userBetReport(params) {
  return request({
    url: '/user/betReport',
    method: 'get',
    params
  })
}

//vip等级用户等级详情
export function levelUserDetail(params) {
  return request({
    url: '/activity/level/userDetail',
    method: 'get',
    params
  })
}

//获取用户头像
export function getAvataList(params) {
  return request({
    url: '/avatar/list',
    method: 'get',
    params
  })
}

//修改用户头像
export function avatarSet(params) {
  return request({
    url: '/avatar/set/' + params,
    method: 'get'
  })
}
// 设置昵称
export function updateNickName(data) {
  return request({
    url: '/user/updateNickName',
    method: 'post',
    data
  })
}

// 设置手机
export function setPhone(data) {
  return request({
    url: '/user/updateUserPhone',
    method: 'post',
    data
  })
}

//充值记录
export function depositRecord(params) {
  return request({
    url: '/deposit/record',
    method: 'get',
    params
  })
}

//提现记录
export function withdrawRecord(params) {
  return request({
    url: '/user/withdraw/record',
    method: 'get',
    params
  })
}

//额度转换记录
export function eduOrderList(params) {
  return request({
    url: '/user/eduOrder/list',
    method: 'get',
    params
  })
}

//帐变记录
export function transactionList(params) {
  return request({
    url: '/user/transactionRecord/list',
    method: 'get',
    params
  })
}

// 获取账变类型
export function transTypeList(params) {
  return request({
    url: '/home/transTypeList',
    method: 'get',
    params
  })
}
// 免提直充详情
export function notWithdrawDetils(params) {
  return request({
    url: '/activity/notWithdraw/detail',
    method: 'get',
    params
  })
}
// 免提直充活动提交
export function notWithdrawPost(data) {
  return request({
    url: '/activity/notWithdraw/post',
    method: 'post',
    data
  })
}
//获取用户详情
export function getUserDetail(params) {
  return request({
    url: '/user/info',
    method: 'get',
    params
  })
}

//获取领取返水金额
export function getReturnAmount(params) {
  return request({
    url: '/user/getReturnAmount',
    method: 'get',
    params
  })
}

//领取返水金额
export function autoReturn(params) {
  return request({
    url: '/user/autoReturn',
    method: 'get',
    params
  })
}
//vip等级奖励列表
export function awardList(params) {
  return request({
    url: '/activity/level/awardList',
    method: 'get',
    params
  })
}

//vip等级奖励记录列表
export function levelRecord(params) {
  return request({
    url: '/activity/level/record',
    method: 'get',
    params
  })
}

//领取等级活动奖励
export function levelReceive(data) {
  return request({
    url: '/activity/level/receive/' + data.levelValue,
    method: 'post',
    data
  })
}

//vip等级晋升说明列表
export function levelupDes(params) {
  return request({
    url: '/activity/level/upDes',
    method: 'get',
    params
  })
}

//修改登录密码
export function updatePassword(data) {
  return request({
    url: '/user/updatePwd',
    method: 'post',
    data
  })
}
//设置交易密码
export function setTransactionPassword(data) {
  return request({
    method: 'post',
    url: '/user/setPayPwd',
    data: data
  })
}
//修改交易密码
export function updateTransactionPassword(data) {
  return request({
    url: '/user/updatePayPwd',
    method: 'post',
    data
  })
}

//站内信
export function messageList(params) {
  return request({
    url: '/user/message/list',
    method: 'get',
    params
  })
}

//站内信已读
export function messageRead(data) {
  return request({
    url: '/user/message/read',
    method: 'post',
    data
  })
}

// 最新站内信
export function messageLatest(data) {
  return request({
    url: '/user/message/latest',
    method: 'get',
    data
  })
}

//获取支付方式
export function getPayMethod(params) {
  return request({
    url: '/pay/getPayMethod',
    method: 'get',
    params
  })
}

//获取第三方支付渠道
export function getPayChannel(params) {
  return request({
    url: '/pay/getPayChannel',
    method: 'get',
    params
  })
}

// 获取第三方支付渠道支付页面
export function getPayUrl(params) {
  return request({
    method: 'get',
    url: '/pay/getPayUrl',
    params
  })
}

//获取线下充值类型
export function findRechargeType(params) {
  return request({
    method: 'get',
    url: '/deposit/getCompany',
    params
  })
}

//获取线下虚拟货币类型
export function depositGetvirtual(params) {
  return request({
    method: 'get',
    url: '/deposit/getvirtual',
    params
  })
}

//提交线下充值
export function companyDeposit(data) {
  return request({
    method: 'post',
    url: '/deposit/companyDeposit',
    data
  })
}

//提交虚拟货币充值
export function companyVirDeposit(data) {
  return request({
    method: 'post',
    url: '/deposit/companyVirDeposit',
    data
  })
}

//获取打码量
export function getWithdrawDama(params) {
  return request({
    method: 'get',
    url: '/user/getWithdrawDama',
    params
  })
}

//获取提现方式
export function getWithdrawList(params) {
  return request({
    method: 'get',
    url: '/user/getWithdrawList',
    params
  })
}

//获取 BANK LIST
export function getBanksList(params) {
  return request({
    method: 'get',
    url: '/home/banks',
    params
  })
}

//绑定银行卡
export function bindBankAccount(data) {
  return request({
    method: 'post',
    url: '/user/bindBankAccount',
    data
  })
}

//绑定虚拟货币收款方式
export function bindVirAccount(data) {
  return request({
    method: 'post',
    url: '/user/bindVirAccount',
    data
  })
}

//绑定数字钱包
export function bindWalletAccount(data) {
  return request({
    method: 'post',
    url: '/user/bindWalletAccount',
    data
  })
}

//用户提现
export function userWithdraw(data) {
  return request({
    method: 'post',
    url: '/user/withdraw',
    data
  })
}

/** 余额宝 */
// 余额宝配置
export const _yebConfig = async () => {
  return await request({ method: 'get', url: '/home/yuebaoConfig' })
}

// 开通余额宝
export const _yebOpen = async () => {
  return await request({ method: 'post', url: '/user/openYuebao' })
}

// 余额宝主页
export const _myYeb = async () => {
  return await request({ method: 'get', url: '/user/yuebao' })
}

// 余额宝转入
export const _yebIn = async (data) => {
  return await request({ method: 'post', url: '/user/depositYuebao', data })
}

// 余额宝转出
export const _yebOut = async (data) => {
  return await request({ method: 'post', url: '/user/withdrawYuebao', data })
}
