import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import BasicLayout from '@/layouts/BasicLayout.vue'
import ProxyLayout from '@/layouts/ProxyLayout.vue'
import IndexView from '@/views/index.vue'
import HomeView from '@/views/home/index.vue'
import { HRouteView } from '@/components'
import userRoutes from '@/router/user'
import proxyRoutes from '@/router/proxy'
import lotteryRoutes from '@/router/lottery'
const routes = [
  {
    path: '/',
    redirect: '/home',
    component: IndexView,
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomeView,
        meta: {
          title: '首页'
        },
        children: [
          {
            path: '/register',
            name: 'register',
            component: () => import('@/views/user/register.vue'),
            meta: {
              title: '注册'
            }
          },
          {
            path: '/pages/res/res',
            redirect: '/register'
          },
          {
            path: '/home',
            name: 'home/index',
            component: () => import('@/views/home/home.vue'),
            meta: {
              title: '首页',
              navId: '0',
              permission: 'home'
            }
          },

          {
            path: '/board',
            name: 'board',
            component: () => import('@/views/board/index.vue'),
            meta: {
              title: '棋牌游戏',
              navId: '2',
              permission: 'home'
            }
          },
          {
            path: '/sports',
            name: 'sports',
            component: () => import('@/views/sports/index.vue'),
            meta: {
              title: '体育赛事',
              navId: '3',
              permission: 'home'
            }
          },
          {
            path: '/live',
            name: 'live',
            component: () => import('@/views/live/index.vue'),
            meta: {
              title: '真人视讯',
              navId: '4',
              permission: 'home'
            }
          },
          {
            path: '/electronic',
            name: 'electronic',
            component: () => import('@/views/electronic/index.vue'),
            meta: {
              title: '电子游戏',
              navId: '5',
              permission: 'home'
            }
          },
          {
            path: '/fishing',
            name: 'fishing',
            component: () => import('@/views/fishing/index.vue'),
            meta: {
              title: '捕鱼游戏',
              navId: '6',
              permission: 'home'
            }
          },
          {
            path: '/memberCentre',
            name: 'memberCentre',
            redirect: '/memberCentre/userInfo',
            component: BasicLayout,
            meta: {
              title: '会员中心',
              permission: 'home',
              login: true // 登录才让访问
            },
            children: [...userRoutes()]
          },

          {
            path: '/promotions',
            name: 'promotions',
            component: () => import('@/views/promotions/index.vue'),
            meta: {
              title: '优惠活动',
              permission: 'home'
            }
          },
          {
            path: '/joinus',
            name: 'joinus',
            component: () => import('@/views/joinus/index.vue'),
            meta: {
              title: '金字塔加盟',
              permission: 'home'
            }
          },
          {
            path: '/about',
            name: 'about',
            component: () => import('@/views/about/index.vue'),
            meta: {
              title: '关于我们',
              permission: 'home'
            }
          },
          {
            path: '/download',
            name: 'download',
            component: () => import('@/views/download/index.vue'),
            meta: {
              title: 'APP下载',
              permission: 'home'
            }
          },
          {
            path: '/redbag',
            name: 'redbag',
            component: () => import('@/views/redbag/index.vue'),
            meta: {
              title: '红包雨',
              permission: 'home',
              login: true
            }
          }
        ]
      },
      {
        path: '/lottery',
        name: 'lottery/index',
        // component: HRouteView,
        component: () => import('@/views/lottery/index.vue'),
        redirect: '/lottery/lhc',
        meta: {
          title: '彩票游戏',
          navId: '1',
          permission: 'home',
          login: true
        },
        children: [...lotteryRoutes]
      }
    ]
  },
  {
    path: '/proxy',
    name: 'proxy',
    redirect: '/proxy/agencyPerformance',
    component: ProxyLayout,
    meta: {
      title: '代理中心',
      permission: 'proxy',
      login: true
    },
    children: [...proxyRoutes]
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/user/login.vue'),
  //   meta: {
  //     title: '登录'
  //   }
  // },
  {
    path: '/:pathMatch(.*)*', // Vue Router 4.x 的通配符匹配
    name: 'NotFound',
    component: () => import('@/views/errorPage/404.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  }
})
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.href = to.fullPath
  }
})

export default router
