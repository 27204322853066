/**
 * @param {object} obj 原始数据
 * @param {string} key1 键名
 * @param {string} key2  键名
 * @param {array} keyOrder 排序数组
 * @returns {object} 指定的价值对对象
 */
function objToSelectOptionsArray(obj, key1 = 'label', key2 = 'value', keyOrder) {
  const arr = []
  if (keyOrder) {
    for (const key of keyOrder) {
      if (obj.hasOwnProperty(key)) {
        arr.push({
          [key1]: obj[key],
          [key2]: key
        })
      }
    }
  } else {
    for (const key in obj) {
      arr.push({
        [key1]: obj[key],
        [key2]: key
      })
    }
  }
  return arr
}

function mapToObjArray(obj, key1) {
  let arr = []
  for (let key in obj) {
    arr.push({
      ...obj[key],
      [key1]: key
    })
  }
  return arr
}

// export const enabledMap = {
//   0: '禁用',
//   1: '启用',
// }
// export const enabledOptions = objToSelectOptionsArray(enabledMap)

// 彩种type映射map
export const lotteryGroupMap = {
  1: 'K3', // 快三
  2: 'SSC', // 时时彩
  3: 'SC', // 赛车
  4: 'LHC', // 六合彩
  5: 'PCDD', // PC蛋蛋
  6: 'SYXW', // 11选5
  7: 'FC3D', // 福彩3D
  8: 'KL8', // 快乐8
  9: 'HH', // 花会
  11: 'DHH' // 大花会
}

// usdt通道
import TRC_20 from '@/assets/usercenter/TRC_20.svg'
import ERC_20 from '@/assets/usercenter/ERC_20.svg'
export const usdtAisleObjMap = {
  TRC20: {
    // address: 'TRC20',
    type: '1',
    icon: TRC_20,
    net: 'TRON'
  }
  // ERC20: {
  //   // address: 'ERC20',
  //   type: '2',
  //   icon: ERC_20,
  //   net: '以太坊'
  // }
}
export const usdtAisleMap = {
  1: 'TRC20',
  2: 'ERC20'
}
export const usdtAisleList = mapToObjArray(usdtAisleObjMap, 'address')
