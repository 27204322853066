import router from '@/router'
import { setDocumentTitle } from '@/utils/index'
import {
  setGlobalDefaultOpenedMenus,
  setGlobalDefaultActiveMenu,
  setGlobalMenus,
  setGlobalHomeDomain
} from './core/global-data'
import userRoutesFn from '@/router/user'
import { homeDomain, getAllLottery, getAllPlat } from '@/api/index'
import { useHomeStore } from '@/stores/home.js'
let userRoutes = []
// let activityNotWithdrawStatus = false

let requestedHomeDomain = ref(false)
// 获取网站配置
async function setHomeDomain() {
  if (requestedHomeDomain.value) {
    return
  }
  const { result } = await homeDomain()
  if (result) {
    userRoutes = userRoutesFn(result)
    const homeStore = useHomeStore()
    homeStore.setHomeDomainData(result)
    setGlobalHomeDomain(result)
    // activityNotWithdrawStatus = result.activityStatus.activityNotWithdrawStatus
    const data = {
      logoImg: result.domain.logo,
      loginImg: result.domain.logo1,
      domainName: result.domain.domainName
    }
    setDocumentTitle(data)
    requestedHomeDomain.value = true
  }
}
// 判断是否显示 免提直充 / VIP权益
async function setAsyncRouter(routerList) {
  // if (activityNotWithdrawStatus) {
  //   return accessedRouters
  // } else {
  //   const accessedRouters = routerList.filter((route) => {
  //     if (route.meta && route.meta.permission !== 'freeWithdraw') {
  //       return route
  //     }
  //   })
  // }
  // return accessedRouters
  return routerList.filter((route) => !route.hidden)
}

router.beforeEach(async (to) => {
  await setHomeDomain()

  const routes = await setAsyncRouter(userRoutes)
  setGlobalMenus(routes)

  return true
  // console.log('77777', to)
  // const hasRoute = routes.some((item) => item.path === to.fullPath)
  // console.log('77777---hasRoute', hasRoute)
  // if (hasRoute) {
  // } else {
  //   router.push('/404')
  //   return false
  // }
})

router.afterEach((to) => {
  const isUserPage = to.matched.find((m) => m.path === '/memberCentre')
  if (isUserPage) {
    const arr = to.matched.filter(
      (item) => item.name !== 'index' && item.meta.showInBreadcrumb !== false
    )
    const index = arr.find((item) => item.meta.isThreeLevelMenu)
    if (index) {
      setGlobalDefaultOpenedMenus([arr.at(-2).path])
      setGlobalDefaultActiveMenu(arr.at(-1).path)
    } else {
      setGlobalDefaultOpenedMenus([arr.at(-2).path])
      setGlobalDefaultActiveMenu(arr.at(-1).path)
    }
  }
})
