<template>
  <div class="sign_box" ref="wheelRef" v-loading="loading">
    <el-dialog v-model="visibleTrunable" class="turnable_dialog" title="" :show-close="false" width="860" center>
      <div v-pre-img class="dialog-main">
        <el-row align="middle" justify="end">
          <div class="detail-main">
            <div class="detail-page">
              <el-row justify="space-between">
                <div class="title-box">
                  <img src="@/assets/turnable/red_gift_title.png" alt="" class="title-img">
                  <span class="btn">¥ {{ statusInfo.totalDeposit.toFixed(2) }}</span>
                </div>
                <img src="@/assets/turnable/red_gift_go_btn.png" alt="" class="btn-img" @click="goDeposit">
              </el-row>
              <div class="total-box">
                <div class="txt" style="display: flex;">
                  <span class="text">当前存款完成进度：</span>
                  <span class="text-val">{{ len ?? '--' }}%</span>
                </div>
                <div class="progress">
                  <div class="progress-len">
                    <div class="progress-bar" :style="{ width: len + '%' }"></div>
                  </div>
                </div>
                <div class="txt" v-if="statusInfo.totalDeposit < statusInfo.notFinishTotalDeposit">
                  领取下次抽奖机会您还需充值<span class="text-val">¥{{ statusInfo.notFinishNeedDeposit.toFixed(2) || '-'
                    }}元</span>
                </div>
              </div>
            </div>
          </div>
        </el-row>

        <el-row align="middle" justify="center">
          <div v-pre-img class="link_btn" @click="showRules"></div>
          <div class="click_btn" :class="statusInfo.notuseJoinCount ? '' : 'click_btn_dis'" @click="startCallback">
            <span>可领次数：{{ statusInfo.notuseJoinCount }}</span>
          </div>
          <div v-pre-img class="link_btn link_btn1" @click="showRecords"></div>
        </el-row>

        <div class="close_con" @click="visibleTrunable = false">
          <el-icon>
            <CircleClose color="#fff" />
          </el-icon>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="showOpenRes" class="res_dialog" :show-close="false" width="860" center>
      <template #header></template>
      <div v-pre-img class="res-bg">
        <div v-pre-img class="res-box">
          <div class="prize-val"><span>¥</span>{{ openRes.award }}</div>
          <el-button v-pre-img class="prize-btn" @click="showOpenRes = false">开心收下</el-button>
          <div class="prize-text">自动存入余额</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="rulesVisiable" class="rules_dialog" title="活动详情" :show-close="true" center align-center>
      <el-scrollbar max-height="70vh" always>
        <div class="content" v-html="rouletteDetails"></div>
      </el-scrollbar>
    </el-dialog>

    <el-dialog v-model="recordsVisiable" class="records_box" title="抽奖记录" :show-close="true" width="620" center>
      <div class="form_item">
        <div class="label">选择日期</div>
        <el-date-picker style="width: 220px; flex-grow: inherit;margin: 10px;" v-model="time" :clearable="false"
          type="daterange" unlink-panels range-separator="~" start-placeholder="起始时间" end-placeholder="截止时间"
          format="YYYY-MM-DD" value-format="YYYY-MM-DD" :shortcuts="shortcuts" />
        <el-button type="primary" @click="getRecords">查询</el-button>
      </div>
      <div class="table_cont">
        <el-table :data="lists" style="width: 100%">
          <el-table-column prop="createTime" label="参与时间" min-width="120px" align="center" />
          <el-table-column prop="prizeAmount" label="彩金" min-width="120px" align="center">
            <template #default="scope">
              <el-text>￥{{ scope.row.prizeAmount || scope.row.amount || 0 }}</el-text>
            </template>
          </el-table-column>
          <el-table-column prop="prizeAmount" label="状态" min-width="120px" align="center">
            <template #default="scope">
              <el-text>{{ (scope.row.prizeAmount > 0 || scope.row.amount) ? '已派发' : '未抽中' }}</el-text>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_box">
          <el-row justify="center" align="middle" style="padding: 10px" class="page_cont">
            <el-pagination :total="totalCount" v-model:page-size="queryParams.limit"
              :page-sizes="[10, 20, 50, 100, 200]" @size-change="handleSizeChange" @change="pageChange"
              layout=" total,sizes,prev, pager, next" ref="pageRef" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { rouletteStatus, roulettePost, rouletteRecords, rouletteDetils } from '@/api/index.js'

import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { shortcuts } from '@/utils/dateUtils'

import useAllImageLoaded from '@/hooks/preloaderImg.hook'
const router = useRouter()

const loading = ref(false)
const disabled = ref(false)
const rouletteDetails = ref('')
const visibleTrunable = ref(false)

import wheelBg from "@/assets/turnable/red_gift_bg.png"
import detailBg from "@/assets/turnable/red_detail_bg.png"
import titleBg from "@/assets/turnable/red_gift_title.png"
import goBtn from "@/assets/turnable/red_gift_go_btn.png"
// import btnImg from '@/assets/turnable/red_gift_btn.png'
// import btnDisabledImg from '@/assets/turnable/red_gift_btn_dis.png'
import btnImg from '@/assets/turnable/red_gift_btn.gif'
import btnDisabledImg from '@/assets/turnable/red_gift_btn_dis.gif'

const len = ref(0)

const openRes = ref({})
const statusInfo = ref({})
const showOpenRes = ref(false)

// 执行预加载
const imgList = [wheelBg, detailBg, titleBg, goBtn, btnImg, btnDisabledImg]
const wheelRef = ref()
useAllImageLoaded(wheelRef, imgList)

const showDialog = () => {
  getData()
}
const getData = async () => {
  loading.value = true
  let { result } = await rouletteStatus()
  statusInfo.value = result.data
  const { totalDeposit, notFinishTotalDeposit } = result.data
  len.value = totalDeposit < notFinishTotalDeposit ? (totalDeposit / notFinishTotalDeposit * 100).toFixed(2) : 100
  visibleTrunable.value = true
  loading.value = false
}

const rulesVisiable = ref(false)
const showRules = async () => {
  let { result } = await rouletteDetils()
  rouletteDetails.value = result.data.detail
  rulesVisiable.value = true
}

const recordsVisiable = ref(false)
const showRecords = async () => {
  recordsVisiable.value = true
  getRecords()
}

const lists = ref([])
const queryParams = ref({
  beginTime: dayjs().format('YYYY-MM-DD'),
  endTime: dayjs().format('YYYY-MM-DD'),
  limit: 10,
  page: 1
})
const time = ref([dayjs().subtract(1, 'months').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])


const totalPages = ref(0)
const totalCount = ref(0)
const handleSizeChange = (num) => {
  queryParams.value.limit = num
  getRecords()
}
const pageChange = (current) => {
  queryParams.value.page = current
  getRecords()
}
const getRecords = async () => {
  loading.value = true
  let params = {
    ...queryParams.value,
    beginTime: time.value[0],
    endTime: time.value[1]
  }
  let { result } = await rouletteRecords('', params)
  lists.value = result.data.list
  totalPages.value = result.data.totalPage
  totalCount.value = result.data.totalCount
}

const goDeposit = () => {
  visibleTrunable.value = false
  router.push('/memberCentre/deposit')
}

async function getTurnClaim() {
  if (disabled.value) {
    return
  }
  openRes.value = {}
  disabled.value = true
  const { result } = await roulettePost()
  disabled.value = false
  if (result) {
    endCallback()
    getData()
    openRes.value = result
  }
}

function startCallback() {
  if (statusInfo.value.notuseJoinCount > 0) {
    getTurnClaim()
  } else {
    ElMessage.error('抱歉，无抽奖次数')
  }
}
function endCallback() {
  showOpenRes.value = true
}

defineExpose({ showDialog })
</script>
<style lang="scss" scoped>
.sign_box {
  :deep(.turnable_dialog) {
    padding: 0 !important;
    background: transparent;

    .dialog-main {
      min-height: 560px;
      background: url('@/assets/turnable/red_gift_bg.png') no-repeat;
      background-size: 100% auto;
    }

    .el-dialog__body {
      padding: 0;
    }

    .detail-main {
      margin: 153px 68px 44px 68px;
      display: flex;
      align-items: center;
      justify-content: center;

      .detail-page {
        padding: 23px 15px 0 29px;
        width: 386px;
        height: 254px;
        background-image: url("@/assets/turnable/red_detail_bg.png");
        background-repeat: no-repeat;
        background-size: 100% auto;

        .title-box {
          display: flex;
          flex-direction: column;

          .title-img {
            width: 130px;
            margin-bottom: 10px;
          }

          .btn {
            background: #FFE9E8;
            border: 1px solid #FF4236;
            border-radius: 900px;
            padding: 5px 15px;
            color: #FF4236;
            font-size: 20px;
          }
        }

        .btn-img {
          width: 77px;
          height: 30px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        .total-box {
          margin-top: 20px;
          padding-right: 20px;

          .txt {
            color: #565656;
            font-size: 16px;
            line-height: 24px;

            .text-val {
              color: #FF4236;
              // font-size: 24px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .link_btn {
      width: 104px;
      height: 72px;
      background: url('@/assets/turnable/red_gift_enter.png') no-repeat;
      background-size: 100% auto;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .link_btn1 {
      background: url('@/assets/turnable/red_gift_record.png') no-repeat;
      background-size: 100% auto;

    }

    .click_btn {
      width: 220px;
      height: 68px;
      margin: 17px 40px 0;
      // background: url('@/assets/turnable/red_gift_btn.png') no-repeat;
      background: url('@/assets/turnable/red_gift_btn.gif') no-repeat;
      background-size: 100% auto;
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      &:hover {
        opacity: 0.8;
      }

      &_dis {
        // background: url('@/assets/turnable/red_gift_btn_dis.png') no-repeat;
        background: url('@/assets/turnable/red_gift_btn_dis.gif') no-repeat;
        background-size: 100% auto;
      }

      span {
        color: #fff;
        font-size: 16px;
        margin-bottom: 7px;
      }
    }

    .close_con {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: -40px;
      font-size: 32px;
    }

    .progress {
      $h: 12px;
      $wStart: 6px;
      $wBall: 28px;
      $hBall: 24px;
      margin: 10px 0;
      height: 30px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: $h;
        background-color: #F4D390;
        border-radius: 99999px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }

      .progress-len {
        width: 100%;
        height: $h;
        padding-left: $wStart;
        padding-right: $wBall;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }

      .progress-bar {
        height: 100%;
        background: url('@/assets/turnable/red_gift_progress.png') repeat;
        background-size: auto 100%;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: $wStart;
          height: $h;
          position: absolute;
          left: calc(1px - $wStart);
          top: 50%;
          transform: translateY(-50%);
          background: url('@/assets/turnable/red_gift_progress_start.png') no-repeat;
          background-size: auto 100%;
        }

        &::after {
          content: '';
          display: block;
          width: $wBall;
          height: $hBall;
          position: absolute;
          right: -$wBall;
          top: 50%;
          transform: translateY(-50%);
          background: url('@/assets/turnable/red_gift_progress_mark.png') no-repeat;
          background-size: auto 100%;
        }
      }
    }
  }

  :deep(.res_dialog) {
    background: rgba(0, 0, 0, 0.7);

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 90px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .res-bg {
      width: 420px;
      height: 506px;
      background: url('@/assets/turnable/red_gift_res_bg.png') no-repeat;
      background-size: auto 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .res-box {
      width: 292px;
      height: 347px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: url('@/assets/turnable/red_gift_res_win.png') no-repeat;
      background-size: auto 100%;

      .prize-val {
        margin-top: 122px;
        color: #FFFBA4;
        font-size: 45px;
        text-align: center;
        line-height: 63px;

        span {
          font-size: 34px;
          margin-right: 10px;
        }
      }

      .prize-text {
        margin-top: 8px;
        color: #fff;
        font-size: 14px;
        line-height: 20px;
      }

      .prize-btn {
        width: 196px;
        height: 62px;
        background: url('@/assets/turnable/red_gift_res_btn.png') no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #B92920;
        font-size: 22px;
        border: 0;
        margin-top: 17px;
        border-radius: 999px;
      }
    }


  }

  :deep(.rules_dialog) {
    padding: 0;

    .el-dialog__body {
      padding: 20px 10px;
    }

    .content {
      max-width: 80vw;
      margin: 0 20px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  :deep(.records_box) {
    .el-dialog__header {
      text-align: left;
    }

    .el-dialog__body {
      .form_item {
        display: flex;
        align-items: center;

        .label {
          font-size: 14px;
          color: #5f5f5f;
        }
      }

      .table_cont {
        margin-top: 20px;

        .el-table {
          th.el-table__cell {
            background-color: #f5f5f5;
            color: #999999;
          }

          .el-table__body-wrapper {
            border-radius: 4px;
          }
        }

        .btn-prev,
        .btn-next {
          width: 60px;
          border-radius: 5px;
          height: 30px;
          display: flex;
          margin-left: 10px;
          align-items: center;
          border: solid 1px #d9dadc;
          background-image: linear-gradient(to bottom, #fff, #eeeeef);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.inner-dialog {
  .el-dialog {
    background: transparent;
    box-shadow: none;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      width: 259px;
      height: 271px;

      background: transparent;

      section {
        width: 100%;
        height: 100%;
        color: #eb162e;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url('@/assets/turnable/pack.png') no-repeat center center;
        background-size: 100%;

        h2 {
          padding-top: 15px;
        }

        p {
          font-size: 14px;
          margin-top: 30px;
          color: #ff512e;

          &.line {
            width: 183px;
            height: 9px;
            background: url('@/assets/turnable/line.png') no-repeat center center;
            background-size: 100%;
            margin: 10px 0;
          }
        }

        .result {
          font-size: 32px;
          color: #ff512e;

          strong {
            font-weight: 600;
          }
        }

        .btn {
          width: 130px;
          height: 38px;
          border-radius: 19px;
          border: solid 1px #fff2ce;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff2ce;
          margin-top: 62px;
          cursor: pointer;
        }
      }
    }
  }

}
</style>
