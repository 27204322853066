<template>
  <el-dialog v-model="dialogVisible" class="notice_dialog" :title="info.title" :append-to-body="true" :show-close="true"
    :close-on-click-modal="false" :close-on-press-escape="false" width="680" center>
    <div class="mind" v-html="info.content"></div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" style="width: 150px; height: 40px" @click="close">
          我知道了
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref } from 'vue'
import { messageLatest, messageRead } from '@/api/usercenter'
const dialogVisible = ref(false)

const info = ref({})

async function messageLatestFn() {
  const { result } = await messageLatest()
  if (result) {
    info.value = result.data[0] || {}
  }
}

async function messageReadFn() {
  await messageRead({ messageIds: info.value.id })
}

async function open() {
  info.value = {}
  await messageLatestFn()
  dialogVisible.value = true
}
function close() {
  messageReadFn()
  dialogVisible.value = false
}

defineExpose({
  open,
  close
})
</script>

<style scoped lang="scss">
.mind {
  @include flexCenter;
  min-height: 100px;
}
</style>
