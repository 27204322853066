import request from '@/utils/request'

// 获取当前期和上期开奖
export function lotteryQsTime(params) {
  return request({
    url: `/lottery/lotteryQsTime`,
    // url: `/lottery/v2/lotteryQsTime`,
    method: 'get',
    params
  })
}

// 历史开奖(最新)
export function openHistoryListLatest(params) {
  return request({
    url: `/lottery/topOpen/list`,
    method: 'get',
    params
  })
}

// 历史开奖(分页)
export function openHistoryList(params) {
  return request({
    url: `/lottery/openHistory/list`,
    method: 'get',
    params
  })
}

// 获取指定彩种下的所有盘口以及玩法(PC版)
export function getAllPlay(lotteryCode, params) {
  return request({
    // url: `/lottery/getAllPlay/V3/${lotteryCode}`,
    url: `/lottery/getAllPlay/V4/${lotteryCode}`,
    method: 'get',
    params
  })
}

// 获取指定彩种下所有玩法的号码以及赔率(PC金字塔)
export function getAllOdds(lotteryCode, playCode, params) {
  return request({
    url: `/lottery/v3/getAllOdds/${lotteryCode}/${playCode}`,
    method: 'get',
    params
  })
}

//彩票投注记录
export function lotteryOrderList(params) {
  return request({
    url: `/lottery/lotteryOrder/list`,
    method: 'get',
    params
  })
}

// 投注
export function bet(data) {
  return request({
    url: `/lottery/v2/bet`,
    method: 'POST',
    data
  })
}

// 彩票撤单
export function lotteryCancel(orderNo, params) {
  return request({
    url: `/lottery/cancel/${orderNo}`,
    method: 'get',
    params
  })
}

// 获取彩种规则
export function lotteryRules(lotteryCode, params) {
  return request({
    url: `/lottery/rules/${lotteryCode}`,
    method: 'get',
    params
  })
}

// 生肖配置
export function shengxiaoConfig() {
  return new Promise(resolve => {
    request({
      url: `/lottery/hk6/sx`,
      method: 'get'
    }).then(res => {
      const { result: { list = [] } = {}} = res
      const zodiacList = {}
    
      list.map(item => {
        if (!zodiacList[item.sxCode]) {
          zodiacList[item.sxCode] = []
        }
        zodiacList[item.sxCode].push(String(item.hm).padStart(2, '0'))
      })
      resolve(zodiacList)
    })
  })
}
