import { HRouteView } from '@/components'
import icon0 from "@/assets/images/agent/dlyj.svg"
import icon1 from "@/assets/images/agent/yqmgl.svg"
import icon2 from "@/assets/images/agent/xjlb.svg"
import icon3 from "@/assets/images/agent/tdzb.svg"
import icon4 from "@/assets/images/agent/yxjl.svg"
import icon5 from "@/assets/images/agent/tdtj.svg"
import icon6 from "@/assets/images/agent/xjcw.svg"
import icon7 from "@/assets/images/agent/xjbb.svg"
import icon0Active from "@/assets/images/agent/dlyj_active.svg"
import icon1Active from "@/assets/images/agent/yqmgl_active.svg"
import icon2Active from "@/assets/images/agent/xjlb_active.svg"
import icon3Active from "@/assets/images/agent/tdzb_active.svg"
import icon4Active from "@/assets/images/agent/yxjl_active.svg"
import icon5Active from "@/assets/images/agent/tdtj_active.svg"
import icon6Active from "@/assets/images/agent/xjcw_active.svg"
import icon7Active from "@/assets/images/agent/xjbb_active.svg"
const proxyRoutes = [
  {
    path: '/proxy/agencyPerformance',
    name: 'proxy/agencyPerformance',
    component: () => import('@/views/proxy/agencyPerformance.vue'),
    meta: {
      title: '代理业绩',
      icon: icon0,
      activeIcon:icon0Active,
      permission: 'agencyPerformance'
    }
  },
  {
    path: '/proxy/invitecodeManage',
    name: 'proxy/invitecodeManag',
    component: () => import('@/views/proxy/invitecodeManage.vue'),
    meta: {
      title: '邀请码管理',
      icon: icon1,
      activeIcon:icon1Active,
      permission: 'invitecodeManage'
    }
  },
  {
    path: '/proxy/subList',
    name: 'proxy/subList',
    component: () => import('@/views/proxy/subList.vue'),
    meta: {
      title: '下级列表',
      icon: icon2,
      activeIcon:icon2Active,
      permission: 'subList'
    }
  },
  {
    path: '/proxy/teamFianance',
    name: 'proxy/teamFianance',
    component: () => import('@/views/proxy/teamFianance.vue'),
    meta: {
      title: '团队账变',
      icon: icon3,
      activeIcon:icon3Active,
      permission: 'teamFianance'
    }
  },
  {
    path: '/proxy/gameRecords',
    name: 'proxy/gameRecords',
    component: HRouteView, // () => import('@/views/proxy/gameRecords.vue'),
    meta: {
      title: '投注记录',
      icon: icon4,
      activeIcon:icon4Active,
      permission: 'gameRecords'
    },
    children: [
      {
        path: '/proxy/records/lottery',
        name: 'proxy/records/lottery',
        component: () => import('@/views/proxy/records/lottery.vue'),
        meta: {
          title: '彩票记录',
          // icon: icon4,
          // activeIcon:icon4Active,
          permission: 'gameRecords'
        }
      },
      {
        path: '/proxy/records/third',
        name: 'proxy/records/third',
        component: () => import('@/views/proxy/records/third.vue'),
        meta: {
          title: '三方记录',
          // icon: icon4,
          // activeIcon:icon4Active,
          permission: 'gameRecords'
        }
      }
    ]
  },
  {
    path: '/proxy/teamAccount',
    name: 'proxy/teamAccount',
    component: () => import('@/views/proxy/teamAccount.vue'),
    meta: {
      title: '团队统计',
      icon: icon5,
      activeIcon:icon5Active,
      permission: 'teamAccount'
    }
  },
  {
    path: '/proxy/subReport',
    name: 'proxy/subReport',
    component: () => import('@/views/proxy/subReport.vue'),
    meta: {
      title: '下级报表',
      icon: icon7,
      activeIcon:icon7Active,
      permission: 'subReport'
    }
  },
  {
    path: '/proxy/subFinance',
    name: 'subFinance',
    redirect: '/proxy/subFinance',
    meta: {
      title: '下级财务',
      icon: icon6,
      activeIcon:icon6Active,
      permission: 'subFinance'
    },
    component: HRouteView,
    children: [
        {
            path: '/proxy/subFinance/depositLog',
            name: 'subFinance/depositLog',
            component: () => import('@/views/proxy/subFinance/depositLog.vue'),
            meta: {
                title: '存款记录',
                permission: 'depositLog'
            }
        },
        {
            path: '/proxy/subFinance/withdrawalLog',
            name: 'subFinance/withdrawalLog',
            component: () => import('@/views/proxy/subFinance/withdrawalLog.vue'),
            meta: {
                title: '取款记录',
                permission: 'withdrawalLog'
            }
        },
        {
            path: '/proxy/subFinance/creditConversion',
            name: 'subFinance/creditConversion',
            component: () => import('@/views/proxy/subFinance/creditConversion.vue'),
            meta: {
                title: '额度转换记录',
                permission: 'creditConversion'
            }
        },
        {
            path: '/proxy/subFinance/bonusRecord',
            name: 'subFinance/bonusRecord',
            component: () => import('@/views/proxy/subFinance/bonusRecord.vue'),
            meta: {
            title: '彩金记录',
            permission: 'bonusRecord'
            }
        }
    ]
  },

]

export default proxyRoutes
