/**
 * <div v-pre-img class="sing"></div>
 * 预加载 dom的背景图
 */

const body = document.body

// 用map去记录指令append的url-div有哪些
const wrappers = new Map([])

export default function createPreLoadImgDirectives(config = { unmount: true }) {
  return {
    mounted(el) {
      if (el.tagName === 'img') {
        // 如果当前是img标签，无需操作，因为Img无论display如何，那么都会进行加载
        return
      }
      // 如果不是img的其他容器，拿到css中的背景图
      const bgUrl = getComputedStyle(el).backgroundImage
      // 将url从css中解构出来
      const url = bgUrl.slice(5, bgUrl.length - 2) // 拿到background-image链接

      // 由于vue指令的this是undefined，所以指令不同生命周期之间不能传递数据，通过把数据挂载到el上
      el._vpreloadUrl = url
      // 创建div img标签在body上
      createImgSection(url)
    },
    unmounted(el) {
      if (config.unmount) {
        // 当指令元素被卸载时，如果开启了unmounted配置
        // 从div img集合中找到对应的div
        const wrapper = wrappers.get(el._vpreloadUrl)
        if (wrapper) {
          // 从body上删除掉wrapper
          body.removeChild(wrapper)
          // 从div img集合中删除掉
          wrappers.delete(el._vpreloadUrl)
        }
      }
    }
  }
}
